import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import Experiences from './Experiences';
import ContactForm from './Contact';
import Projects from './Projects';
import { InlineWidget } from 'react-calendly';

const Window = ({ type, data, visibility, closeWindow, background, windowName, width, height, expandWindow, windowExpanded}) => {
  const [numPages, setNumPages] = useState<number>();
  const [current, setCurrent] = useState(0);
  const [showPrompt, setShowPrompt] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


  function isCalendlyEvent(e) {
    return e.data.event &&
           e.data.event.indexOf('calendly') === 0;
  };
  
  useEffect(() => {
    // Listen for Calendly event
    if(type==='contact'){
      window.addEventListener("message", function(e) {
        if (isCalendlyEvent(e)) {
          if (e.data.event === "calendly.page_height") {
            const newHeight = e.data.payload.height;
            if (contentRef.current) {
              contentRef.current.style.height = newHeight;
            }
          }
        }
      });
    }

    // Wait for the animation to finish before starting the typing effect
    if (visibility) {
      const timeout = setTimeout(() => {
        setIsVisible(true); // Set visible after the animation completes
      }, 5000); // Adjust this to match your animation duration
      
      return () => clearTimeout(timeout); // Cleanup timeout on unmount
    } else {
      setIsVisible(false); // Reset visibility when component is hidden
    }
  }, [visibility]);

  useEffect(() => {
    if (isVisible && type === 'console') {
      if (current < data.length) {
        setTimeout(() => setShowPrompt(true), 500); // Delay for prompt appearance

        setTimeout(() => {
          setShowPrompt(false);
          setCurrent(current + 1);
        }, 2500 + data[current].prompt.length * 100); // Adjust timing based on prompt length
      }
    }
  }, [current, data, isVisible]); // Dependency now includes `isVisible`


  const renderContent = () => {
    switch (type) {
      case 'pdf':
        return (
          <Document
            file={`${process.env.PUBLIC_URL}/static/cv.pdf`}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                scale={3}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        );
      case 'exp':
        return <Experiences />;
      case 'video':
        return <div>
              <video
                loop
                autoPlay
                controls
                style={{ maxWidth: '100%', height: 'auto', filter:'none' }}
                onError={(e) => console.error('Error loading video:', e)}
              >
                <source src="/static/interview.mp4" type="video/mp4" />
                Your browser does not support HTML5 video.
              </video>
            </div>
      case 'contact':
        return <InlineWidget url="https://calendly.com/benjamin-guendouz" />;
      case 'console':
        return (
          <div style={{fontFamily:'"Nerd Font", sans-serif', padding:'15px'}}>
            {data.slice(0, current).map((item, index) => (
              <React.Fragment key={index}>
                <span className="prompt">Ben@ONLINE-GZDV2023</span>:<span className="prompt_message">~$/home</span>$ <span className="message">{item.prompt}</span>
                <br />
                <span style={{marginLeft: '10%'}}>{item.output}</span>
                <br />
              </React.Fragment>
            ))}
            {current < data.length && (
              <div>
                <span className="prompt">Ben@ONLINE-GZDV2023</span>:<span className="prompt_message">~$/home</span>{' '}
                <span className="message">
                  {showPrompt && <TypingEffect text={data[current].prompt} initialDelay={2000} />}
                </span>
              </div>
            )}
          </div>
        );
      case 'projects':
        return <Projects />;
        
      case 'linkedin':
        return <iframe style={{border: 'none',height:'50vh',width:'100%'}} src="https://www.linkedin.com/in/benjamin-guendouz-533419134/" ></iframe>;
      default:
        return null;
    }
  };

  const TypingEffect = ({ text, initialDelay = 2000 }) => {
    const [displayedText, setDisplayedText] = useState('');
    const timeouts = useRef<NodeJS.Timeout[]>([]);

    useEffect(() => {
      setDisplayedText(''); // Reset displayed text

      // Typing effect
      text.split('').forEach((char, index) => {
        const timeout = setTimeout(() => {
          setDisplayedText((prev) => prev + char);
        }, initialDelay + index * 100); // Typing speed
        timeouts.current.push(timeout);
      });

      // Cleanup timeouts on unmount
      return () => {
        timeouts.current.forEach(clearTimeout);
        timeouts.current = [];
      };
    }, [text, initialDelay]);

    return (
      <>
        <span>{displayedText}</span>
        {!displayedText && <span className="blink-cursor">|</span>}
      </>
    );
  };

  return (
    <div
      className={`window boxShadow transparent ${visibility ? 'visible' : 'closing'}`}
      style={{
        width: width ? width : '100%', // Fullscreen in expanded mode
      }}
    >
      <div className='clickMe'>Click me !</div>
      <header style={{ position: 'relative', paddingBottom: '3px', height: '2.5vh' }}>
        { type === 'pdf' || type === 'console' ? <div className="window-header light-gray">{windowName}</div> : windowName }
        <div className="window-header-button">
          <div className="button" onClick={closeWindow}>
            -
          </div>
          <div className="button" onClick={expandWindow}>
            O
          </div>
          <div className="button"  onClick={closeWindow}>X</div>
        </div>
      </header>
        <div id="window" ref={contentRef}  className="scrollable"   
        style={{
          overflow: type == 'pdf' || type == 'exp' ? 'auto' : type === 'console' ? 'hidden' : 'visible',
          height: windowExpanded ? '100vh' : height ? height : 'auto',
        }}>
          
      <div
        id="window-bg"
        style={{
          height: '100%',
          backgroundImage: `url(${background})`, // Use backgroundImage for image backgrounds
          backgroundSize: 'cover',               // Optional: Ensures the image covers the entire div
          backgroundPosition: 'center',          // Optional: Centers the image within the div
          position: 'absolute', // Relative to this fixed container
          zIndex:'-1'
        }}
      >
      </div>
          {renderContent()}
        </div>
    </div>
  );
};

export default Window;
